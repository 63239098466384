<template>
  <section
    id="avis"
    data-nosnippet
  >
    <i class="fa fa-thumbs-up" />
    <i class="fa fa-comments" />
    <h2>
      Ils parlent de Cyclo Truck
    </h2>
    <div class="stars">
      <div>5/5</div>
      <div>
        <i class="fa fa-star" />
        <i class="fa fa-star" />
        <i class="fa fa-star" />
        <i class="fa fa-star" />
        <i class="fa fa-star" />
      </div>
      <a
        target="_blank"
        href="https://www.google.com/search?sa=X&sca_esv=4cfa0bda60f78d2e&tbm=lcl&q=Cyclo+truck+et+Cyclo+truck+Le
+Shop+Avis&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIxNLe0NDOxsDC0NLM0NLUwBRLGGxgZXzGqO1cm5-QrlBSVJmcrpJYoIHN9UhWCM_ILFB
zLMosXsRKrEgCrkwRXbwAAAA&rldimm=17996488196915859153&hl=fr-FR&ved=2ahUKEwiIk-G846aEAxVNVqQEHZCVCmQQ9fQKegQIGxAF&biw=131
4&bih=892&dpr=2#lkt=LocalPoiReviews"
        title="Voir les avis"
      >
        +50 avis
      </a>
    </div>
    <div
      v-if="notPreRendered"
      class="list"
    >
      <div class="col">
        <div class="glass element big">
          <div>Philippe F.</div>
          C'est un service génial, je suis complètement fan. <br />
          On tombe sur quelqu'un d'efficace
          agréable et qui sait de quoi il parle. Les prix sont compétitifs mais surtout on a le choix entre
          la prestation sur place et à domicile. Je reviendrais c'est sur.
        </div>
        <div class="glass element">
          <div>Myriam M.</div>
          Chaîne neuve et en bonus nettoyage / dégraissage complet de la transmission, mon vtt reviens plus
          propre qu’il est parti ! J’adore 😊
        </div>
        <div class="glass element">
          <div>Karen S.</div>
          Super satisfaite de la prestation. Hyper réactif et très pro, il a remis à neuf mes 2 vélos qui en
          avaient bien besoin et tout ça à domicile. 👍 Rapport/qualité prix au top.
        </div>
      </div>
      <div class="col">
        <div class="glass element">
          <div>Hélène H.</div>
          Alexandre est très efficace et pro. Je le recommande.
        </div>
        <div class="glass element big">
          <div>Anthony D.</div>
          Alexandre est très professionnel et super réactif. Il se décarcasse pour trouver la meilleure solution
          pour ses clients en un temps record. Le rapport qualité/prix est excellent et avec le sourire en plus.
          <br/>Une adresse a garder sous le coude !
        </div>
        <div class="glass element big">
          <div>Tania L.</div>
          Service rapide et soigné. Avec en prime des conseils personnalisés avec le sourire !<br />
          Révision sur un vélo Amsterdamer.<br />
          Je recommande vivement !
        </div>
      </div>
      <div class="col">
        <div class="glass element">
          <div>Anne-Marie S.</div>
          J'ai contacté Cyclo Truck pour quelques réparations sur mes vélos.
          Alexandre m'a proposé un rdv assez rapidement. Il est intervenu à mon domicile, très pratique,
          et a réalisé une prestation de qualité en un temps record. Il est d'excellent conseil. <br />
          Je recommande vivement ce professionnel.

        </div>
        <div class="glass element">
          <div>Elisabeth V.</div>
          Je suis passée samedi après-midi pour faire changer une chambre à air car la roue était à plat.
          En moins de 10 minutes c'était fait. Merci pour l'accueil, la rapidité du service et les conseils.
          Je recommande ce magasin.
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Avis",
  computed: {
    ...mapGetters(["notPreRendered"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/variables.scss" as *;

#avis {
  .stars {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
    > :nth-child(2) {
      margin: 1rem 0;
    }
    .fa-star {
      font-size: 3rem;
      color: var(--secondary-color);
    }
  }
  .list {
    display: flex;
    flex-direction: row;
    @include media-small() {
      flex-direction: column;
    }
    padding: 1rem;
    gap: 2rem;
    margin-top: 3rem;
    .col {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      flex: 1;
    }
    .element {
      flex: 1;
      padding: 1rem;
      @include media-small() {
        &:nth-of-type(2n) {
          display: none;
        }
      }
      &.big {
        flex: 2;
      }
      > div {
        color: var(--ascent-color-light-45);
        font-style: italic;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
    }
  }
  a {
    color: inherit;
    text-decoration: none;
    border-bottom: var(--white-color) 1px solid;
    &:hover {
      @include gradient-text();
    }
  }

  position: relative;
  .fa-thumbs-up {
    @include bg-icon(15rem);
    top: -5rem;
    right: 10rem;
    transform: rotate(15deg)
  }
  .fa-comments {
    @include bg-icon(15rem);
    bottom: -10rem;
    left: 10rem;
  }
}
</style>
